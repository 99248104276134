import React, { AnchorHTMLAttributes, FunctionComponent, MouseEvent } from 'react';

import Link, { LinkProps } from 'next/link';

interface Tracking {
  action: string;
  eventLabel?: string;
  eventCategory?: string;
}

interface AnchorProps {
  className?: string;
  tracking?: Tracking;
}

const Anchor: FunctionComponent<LinkProps & AnchorProps & AnchorHTMLAttributes<HTMLAnchorElement>> = ({ className, ...props }) => {
  const { href, as, replace, scroll, shallow, passHref, prefetch, locale, onClick, ...anchorProps } = props;

  const hrefProps = href || '#';

  const linkProps = {
    href: hrefProps,
    prefetch: false,
    ...(!!as && { as }),
    ...(!!scroll && { scroll }),
    ...(!!locale && { locale }),
    ...(!!replace && { replace }),
    ...(!!shallow && { shallow }),
    ...(!!passHref && { passHref }),
    ...(!!prefetch && { prefetch }),
  };

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Link {...linkProps}>
      <a {...anchorProps} href={hrefProps} className={className} draggable={false} onClick={handleClick}>
        {props.children}
      </a>
    </Link>
  );
};

Anchor.defaultProps = {
  href: '#',
};

export default Anchor;
