import React, { useState, useEffect, forwardRef, SyntheticEvent, ImgHTMLAttributes } from 'react';

import classnames from 'classnames';

import styles from './Image.module.scss';

export interface ImageProps {
  src: string;
  alt?: string;
  lazySrc?: string;
  errorSrc?: string;
  className?: string;
  // originSrc?: string;
}

const Image = forwardRef<HTMLImageElement, ImageProps & ImgHTMLAttributes<HTMLImageElement>>(({ src, className, lazySrc, errorSrc, ...props }, ref) => {
  const [imgSrc, setImgSrc] = useState(src || lazySrc);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    setImgSrc(src || lazySrc);
  }, [src]);

  const handleImgError = (event: SyntheticEvent<HTMLImageElement>) => {
    if (event.type === 'error') {
      setIsError(true);
      setImgSrc(errorSrc);
    }
  };

  return <img {...props} ref={ref} src={imgSrc} onError={handleImgError} className={classnames([styles.wrapper, className, { [styles.error]: isError }])} />;
});

Image.defaultProps = {
  className: '',
  alt: 'eDoctor',
  lazySrc: '/assets/default/1.gif',
  errorSrc: '/assets/default/default_img.png',
};

export default Image;
