import React, { ReactNode, FunctionComponent } from 'react';

// Contexts
import { useViewDetected } from '@Components/Utils/ViewDetected';

// Styles
import styles from './Container.module.scss';

// Utilities
import classnames from 'classnames';

interface LayoutContainerProps {
  children: ReactNode;
  className?: string;
}

const LayoutContainer: FunctionComponent<LayoutContainerProps> = ({ className, children }) => {
  const { deviceType } = useViewDetected();

  return <div className={classnames([styles.container, styles[deviceType], className])}>{children}</div>;
};

export default LayoutContainer;
